import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Button from "components/_ui/Button";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";

const FormWrapper = styled("div")`
    text-align: center;
    background-color: ${colors.grey800};
    padding: 1.5em;
`

const FormTitle = styled("h3")`
    margin-top: 0;
    color: ${colors.grey100};
`

const FormPrompt = styled("p")`
    margin-bottom: 1.5em;
    color: ${colors.grey100};
`

const FormStateMessage = styled("p")`
    color: ${colors.orange500};
`

const FormDisclaimer = styled("p")`
    margin-top: 0.5em;
    color: ${colors.grey100};
`

const FormInput = styled("input")`
    margin-right: 0.5em;
    padding: 0.75em 19em 0.75em 1em;
    border: 0.5px solid #7D7F81;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        padding-right: 12.5em;
        margin-bottom: 2em;
    }
`

const ActualForm = styled("form")`
    padding-bottom: 1.5em;
`

export default class MailchimpForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = { email: '', message: '' };
  }

  handleInputChange(e) {
      this.setState({ email: e.target.value });
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email);
    this.setState({ message: result.msg })
  }

  render () {
    return (
    <FormWrapper>
      <FormTitle>
        {this.props.titleText ? this.props.titleText : 'Like my work?'}
        </FormTitle>
      <FormPrompt>Let me send updates to your inbox (and one day, a newsletter).</FormPrompt>
      {this.state.message !== '' ? <FormStateMessage>{this.state.message}</FormStateMessage> : null}
      <ActualForm onSubmit={this.handleSubmit}>
        <FormInput type="email" value={this.state.email} onChange={this.handleInputChange.bind(this)} name="email" aria-label="Enter your email address" placeholder="Email address" />
        <Button type="submit" className="Button--form">
            Get it
        </Button>
      </ActualForm>
      <FormDisclaimer>Unsubscribe anytime. No spam <span role="img" aria-label="">🤖</span></FormDisclaimer>
    </FormWrapper>
    )
  }
}